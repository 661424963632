import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from 'react-slick';

import PostCarousel from "../components/PostCarousel";
import { NavLink, useNavigate } from "react-router-dom";

import NewsTicker from 'react-advanced-news-ticker';
import {useSelector} from 'react-redux'
import { getSlider, getLatestPost } from "../http";
import { useState, useEffect, useRef } from "react";
import {Helmet} from 'react-helmet'

function NextBtn ({onClick}) {
    return (
        <button onClick={onClick} style={{background: 'transparent', border:'none', outline: 'none', color: '#cc0001'}} className="hero-carsoul-btn hero-crasou-next">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
</svg>
        </button>
    )
}

function PrevBtn ({onClick}) {
    return (
        <button onClick={onClick} style={{background: 'transparent', border:'none', outline: 'none', color: '#cc0001'}} className="hero-carsoul-btn hero-crasou-pre">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg>
        </button>
    )
}


export default function Home () {

    var settings = {
        dots: false,
        arrow: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextBtn />,
        prevArrow: <PrevBtn />,
        
    };

    const {postTypes} = useSelector(state => state.postTypes);
    const [slides, setSlides] = useState([]);
    const [latestPost, setLatestPost] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSlid = async () => {
            try {
               const {data} = await getSlider();
               setSlides(data.sliders)
            } catch (error) {
                console.log(error)
            }
        }
        fetchSlid();
    }, []);

    useEffect(() => {
        const fetchLatest = async () => {
            try {
                const {data} = await getLatestPost();
                setLatestPost(data)
            } catch (error) {
               console.log(error) 
            }
        }
        fetchLatest();
    }, [])

    const [isPaused, setIsPaused] = useState(true);
    const mref = useRef(null)

    useEffect(() => {
        if(!isPaused){
            mref.current.stop();
        }else{
            mref.current.start()
        }
    }, [isPaused])


    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Metia IAS Academy - Homepage</title>
          <meta name="description" content="Metia IAS Academy - Homepage" />
        </Helmet>
        <section id="hero">
          <div className="hero-row">
            <div className="hero-left" style={{ position: "relative" }}>
              <Slider {...settings}>
                {slides.map((sl) => (
                  <div>
                    <img alt="slider" src={sl.image} />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="hero-righ">
              <div className="hero-right-header">
                <h1 style={{ color: "white" }}>What's New</h1>
              </div>
              <div
                onMouseEnter={() => setIsPaused(false)}
                onMouseLeave={() => setIsPaused(true)}
                className="hero-right-body"
                style={{
                  padding: "30px",
                  position: "relative",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <marquee
                  ref={mref}
                  direction="up"
                  height="400"
                  scrollamount={2}
                >
                  <div>
                    {latestPost.map((lp) => (
                      <p
                        className="latest-link"
                        style={{
                          marginBottom: "8px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <NavLink to={`/post/${lp.post_type.slug}/${lp.slug}`}>
                          <a
                            href="#"
                            className="footer-link"
                            style={{ color: "white" }}
                          >
                            <svg
                              style={{ marginRight: "7px" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-chevron-right"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                              />
                            </svg>
                            {lp.title.length > 44
                              ? `${lp.title.slice(0, 44)}...`
                              : lp.title}
                          </a>
                        </NavLink>
                      </p>
                    ))}
                  </div>
                </marquee>
              </div>
            </div>
          </div>
        </section>
        <section id="post-carousel" style={{ padding: "60px 0" }}>
          <div className="my-container">
            <PostCarousel
              label="Current Affairs"
              slug="current-affairs"
              type="post"
            />
            {postTypes.map((pt) =>
              pt.slug != "current-affairs" ? (
                <PostCarousel label={pt.label} slug={pt.slug} type="cat" />
              ) : null
            )}
            <PostCarousel label="Blogs" slug="blogs" type="post" />
          </div>
          <div className="my-container">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "60px 0 30px 0",
              }}
            >
              <h1 className="primary-heading">Categories</h1>
            </div>
            <div className="category-grid">
              {postTypes.map((pt) =>
                pt.slug != "blogs" ? (
                  <div
                    className="cat-cart"
                    onClick={() => navigate(`/posts/${pt.slug}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="cat-box">
                      <div className="craousel-card-thumbnail">
                        <img
                          src={pt.image}
                          style={{
                            width: "100%",
                            height: "170px",
                            objectFit: "cover",
                          }}
                          alt="thumbnail"
                        />
                      </div>
                      <div className="craouser-card-heading">
                        <h2 className="secondary-heading">{pt.label}</h2>
                      </div>
                    </div>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </section>

     ;   </div>
    )
}
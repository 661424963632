import PageHeader from "../components/PageHeader";
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import { useState, useEffect } from "react";
import {getPosts, getSubCategories, getSubChildCategories} from '../http';
import Pagination from '@mui/material/Pagination';
import { Helmet } from "react-helmet";

export default function Category ({label, slug, postType}){

    let [searchParams, setSearchParams] = useSearchParams();
  

    const {post_type, category} = useParams();
    const [sub_category, setSubCategory] = useState(searchParams.get('ct') || 'all');
    const [sub_child_category, setSubChildCategory] = useState('all')
    const [page, setPage] = useState(1);

    const [subCategories, setSubCategories] = useState([]);
    const [subChildCategories, setSubCHildCategories] = useState([]);
    const [posts, setPosts] = useState([]);
    const [total, setTotal] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
       const fetchPosts = async () => {
        try {
            const {data} = await getPosts(post_type, category, sub_category, sub_child_category, page);
            
            setPosts(data.posts);
            setTotal(data.total)
        } catch (error) {
            console.log(error)
        }
       }
       fetchPosts();
    }, [post_type, category, sub_category, page, sub_child_category]);

    useEffect(() => {
        const fetchSubCategory = async () => {
            try {
                const {data} = await getSubCategories(post_type, category);
                setSubCategories(data.subCategories)
            } catch (error) {
                console.log(error)
            }
        }
        fetchSubCategory()
    }, [post_type, category])

    useEffect(() => {
        const fetchSubChildCategories = async () => {
            try {
               const {data} = await getSubChildCategories(post_type, category, sub_category);
               setSubCHildCategories(data.subChildCategories); 
            } catch (error) {
               console.log(error)  
            }
        }
        fetchSubChildCategories();
    }, [post_type, category, sub_category])

    const handleCategory = (cat) => {
        setSubChildCategory('all');
        setSubCategory(cat)
    }


    


    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Metia IAS Academy - Category</title>
          <meta name="description" content="Metia IAS Academy - Category" />
        </Helmet>
        <PageHeader
          label={category
            .split("-")
            .map((t) => t[0].toUpperCase() + t.slice(1, t.length))
            .join(" ")}
          slug=""
        />
        <section id="post-type" style={{ padding: "60px 0" }}>
          <div className="my-container">
            {subCategories.length > 1 && (
              <div
                className="sub-category-nav"
                style={{ marginBottom: "10px" }}
              >
                <ul>
                  <li
                    style={{
                      border: "1px solid white",
                      borderRadius: "7px",
                      padding: "4px 15px",
                      borderColor: sub_category == "all" ? "#cc0001" : "white",
                    }}
                    className={sub_category == "all" ? "active" : ""}
                    onClick={() => handleCategory("all")}
                  >
                    All
                  </li>
                  {subCategories.map((sc) => (
                    <li
                      style={{
                        border: "1px solid white",
                        borderRadius: "7px",
                        padding: "4px 15px",
                        borderColor:
                          sub_category == sc.slug ? "#cc0001" : "white",
                      }}
                      className={sub_category == sc.slug ? "active" : ""}
                      onClick={() => handleCategory(sc.slug)}
                    >
                      {sc.label}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {subChildCategories.length >= 1 && sub_category != "all" ? (
              <div className="sub-category-nav">
                <ul>
                  <li
                    className={sub_child_category == "all" ? "active" : ""}
                    onClick={() => setSubChildCategory("all")}
                  >
                    All
                  </li>
                  {subChildCategories.map((sc) => (
                    <li
                      className={sub_child_category == sc.slug ? "active" : ""}
                      onClick={() => setSubChildCategory(sc.slug)}
                    >
                      {sc.label}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <div className="grid-container">
              {posts.map((post) => (
                <div
                  className="craousel-card"
                  onClick={() => navigate(`/post/${post_type}/${post.slug}`)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="craouser-card-box">
                    <div className="craousel-card-thumbnail">
                      <img
                        src={post.image}
                        style={{
                          width: "100%",
                          height: "170px",
                          objectFit: "cover",
                        }}
                        alt="thumbnail"
                      />
                    </div>
                    <div className="craouser-card-heading">
                      <h2 className="secondary-heading">
                        {post.title.length > 25
                          ? `${post.title.slice(0, 25)}...`
                          : post.title}
                      </h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {total > 12 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <Pagination
                count={Math.ceil(total / 12)}
                page={page}
                onChange={(e, v) => setPage(v)}
                variant="outlined"
                color="secondary"
              />
            </div>
          )}
        </section>
      </div>
    );
}
import PageHeader from "../components/PageHeader";
import { useNavigate } from "react-router-dom";

export default function Mains () {

    const navigate = useNavigate()
  
    return (
        <div>
           <PageHeader label='Mains' slug='' />
           <section id="post-type" style={{padding: '60px 0'}}>
            
            <div className="my-container">
               <div className="grid-container">

                
                        <div className="craousel-card" style={{cursor: 'pointer'}} onClick={() => navigate(`/posts/upsc-cse/essay`)}>
                        <div className="craouser-card-box">
                        <div className="craousel-card-thumbnail">
                             <img src='https://www.metisias.com/uploads/mainsEssay.jpg' alt="thumbnail" />
                         </div>
                         <div className="craouser-card-heading">
                             <h1 className="secondary-heading">Essay</h1>
                         </div>
                        </div>
                     </div>

                     <div className="craousel-card" style={{cursor: 'pointer'}} onClick={() => navigate(`/posts/upsc-cse/gs`)}>
                        <div className="craouser-card-box">
                        <div className="craousel-card-thumbnail">
                             <img src='https://www.metisias.com/uploads/gs -cetgiri.jpg' alt="thumbnail" />
                         </div>
                         <div className="craouser-card-heading">
                             <h2 className="secondary-heading">Gs</h2>
                         </div>
                        </div>
                     </div>

                     <div className="craousel-card" style={{cursor: 'pointer'}} onClick={() => navigate(`/posts/upsc-cse/optional`)}>
                        <div className="craouser-card-box">
                        <div className="craousel-card-thumbnail">
                             <img src='https://www.metisias.com/uploads/mainsopt.jpeg' alt="thumbnail" />
                         </div>
                         <div className="craouser-card-heading">
                             <h2 className="secondary-heading">Optional</h2>
                         </div>
                        </div>
                     </div>

                     <div className="craousel-card" style={{cursor: 'pointer'}} onClick={() => navigate(`/posts/upsc-cse/personality-test`)}>
                        <div className="craouser-card-box">
                        <div className="craousel-card-thumbnail">
                             <img src='https://www.metisias.com/uploads/ptest.jpeg' alt="thumbnail" />
                         </div>
                         <div className="craouser-card-heading">
                             <h2 className="secondary-heading">Personality Test</h2>
                         </div>
                        </div>
                     </div>

                </div>
            </div>
           </section>
        </div>
    )
}
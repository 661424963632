import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { validateEmail } from "../tools/validator";
import { useState } from "react";
import { createSubscriber } from "../http";

export default function Footer() {
  const { pathname } = useLocation();

  const { postTypes } = useSelector((state) => state.postTypes);

  const [email, setEmail] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleUpdate = async () => {
    if (!validateEmail(email)) {
      toast.error("Invalid email given", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    try {
      const { data } = await toast.promise(
        createSubscriber({
          email,
        }),
        {
          pending: "Subscribing...",
          success: "Subscribed",
        }
      );
      setEmail("");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
  };

  return (
    <div>
      <ToastContainer />
      <section id="subscribe">
        <div className="my-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="sub-box"
          >
            <div>
              <h1 style={{ color: "#fff" }} className="primary-heading">
                Subscribe our newsletter for latest Update
              </h1>
            </div>
            <div style={{ display: "flex" }}>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ background: "#fff", minWidth: "250px" }}
                type="email"
                placeholder="Enter your email"
              />
              <button
                onClick={handleUpdate}
                style={{ marginLeft: "20px", minWidth: "120px" }}
                className="primary-button submit-button"
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </section>
      <section id="footer">
        <div className="my-container footer-top">
          <div class="row">
            <div class="footer-col">
              <img
                alt="logo"
                src="/assets/img/metis-logo-white.png"
                style={{ width: "180px" }}
              />

              {/* <center>  <p style={{ color: 'white' }}>
                            <i class="fa-brands fa-facebook" style={{ marginRight: '10px' }}></i>
                            <i class="fa-brands fa-instagram" style={{ marginRight: '10px' }}></i>
                            <i class="fa-brands fa-twitter" style={{ marginRight: '10px' }}></i>
                        </p></center> */}
            </div>
            <div class="footer-col" style={{ paddingLeft: "40px" }}>
              <h4 class="widget-title">Quick Links</h4>
              <ul className="footer-nav">
                <li>
                  <NavLink to={"/"}>
                    <a className="footer-link">Home</a>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/about-us"}>
                    <a className="footer-link">About Us</a>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/contact-us"}>
                    <a className="footer-link">Contact Us</a>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/blogs"}>
                    <a className="footer-link">Blogs</a>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div class="footer-col">
              <h4 class="widget-title">Important Links</h4>

              <ul className="footer-nav">
                {postTypes.map((pt) => (
                  <li>
                    {pt.slug != "blogs" && (
                      <NavLink to={`/posts/${pt.slug}`}>
                        <a href="#" className="footer-link">
                          {pt.label}
                        </a>
                      </NavLink>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div class="footer-col" style={{ paddingRight: "40px" }}>
              <h4 class="widget-title">Contact us</h4>

              <ul className="footer-nav">
                <li>
                  <a
                    href="tel:+919502190061"
                    className="footer-link footer-contact"
                  >
                    <i class="fa-solid fa-phone"></i>{" "}
                    {/* Adjust padding here */}
                    <span>+91 98765 43210</span>
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:bingefiesta@gmail.com"
                    className="footer-link footer-contact"
                  >
                    <i class="fa-solid fa-envelope"></i>{" "}
                    {/* Adjust padding here */}
                    <span>info@metisias.com</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="footer-link footer-contact">
                    <i class="fa-solid fa-location-dot"></i>{" "}
                    {/* Adjust padding here */}
                    <span>Hyderabad, Telangana - India</span>
                  </a>
                </li>
              </ul>
              <p style={{ color: "white" }}>
                <a
                  href="https://www.facebook.com/share/U3Ves3r7tD6G1SKT/?mibextid=qi2Omg"
                  style={{ color: "#fff" }}
                  target="_blank"
                >
                  <i
                    class="fa-brands fa-facebook"
                    style={{ marginRight: "10px" }}
                  ></i>
                </a>
                <a
                  href="https://www.instagram.com/bingenfiesta?igsh=ZDE4czFrdGsxMDNh"
                  style={{ color: "#fff" }}
                  target="_blank"
                >
                  <i
                    class="fa-brands fa-instagram"
                    style={{ marginRight: "10px" }}
                  ></i>
                </a>
                {/* <i class="fa-brands fa-twitter" style={{ marginRight: '10px' }}></i> */}
              </p>
            </div>
          </div>

          <div class="social-icons">
            <a
              href="https://www.linkedin.com/company/metis-ias-academy-hyderabad/"
              target="_blank"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                alt="LinkedIn"
              />
            </a>
            <a
              href="https://www.instagram.com/metis_ias_academy?igsh=ZHM4cXExcDZjMXhu"
              target="_blank"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
                alt="Instagram"
              />
            </a>
            <a href="https://t.me/+K9AupcA_ZDxmNGNl" target="_blank">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"
                alt="Telegram"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCuISphm7SyTynqZPyRnMAZQ"
              target="_blank"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/4/42/YouTube_icon_(2013-2017).png"
                alt="YouTube"
              />
            </a>
          </div>
        </div>
        <div className="my-container footer-bottom">
          <div className="row">
            <ul className="copyright-list">
              <li>
                <span className="footer-link">
                  Copyright &copy; {new Date().getFullYear()} All Right Reserved
                  By Metis.{" "}
                </span>
              </li>
              <li>
                <span className="footer-link">
                  Developed By{" "}
                  <a
                    href="https://www.waytowebs.com"
                    target="_blank"
                    className="footer-link"
                  >
                    Waytowebs
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
